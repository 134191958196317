import Swiper from 'swiper';

// Main visual Slide Show
if (document.querySelector('.theme-mv-imgs__slideshow') !== null) {
  // eslint-disable-next-line no-unused-vars
  const themeMvSlideShow = new Swiper('.theme-mv-imgs__slideshow .swiper-container', {
    speed: 3000,
    effect: 'fade',
    fade: {
      crossFade: true,
    },
    autoplay: {
      delay: 3000,
    },
    loop: true,
    pagination: {
      el: '.theme-show-mv-imgs .swiper-pagination',
      clickable: true,
    },
  });
}

// All Spots Carousel
if (document.querySelector('.theme-swiper') !== null) {
  // eslint-disable-next-line no-unused-vars
  const testSwiper = new Swiper('.theme-swiper .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.theme-swiper .cmn-carousel__pager-inner--type_next',
      prevEl: '.theme-swiper .cmn-carousel__pager-inner--type_prev',
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      420: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
  });
}
