import $ from 'jquery';
import Swiper from 'swiper';

// Main visual Slide Show
if (document.querySelector('.stories-mv-imgs__slideshow') !== null) {
  // eslint-disable-next-line no-unused-vars
  const storiesMvSlideShow = new Swiper('.stories-mv-imgs__slideshow .swiper-container', {
    speed: 3000,
    effect: 'fade',
    fade: {
      crossFade: true,
    },
    autoplay: {
      delay: 3000,
    },
    loop: true,
    pagination: {
      el: '.stories-show-mv-imgs .swiper-pagination',
      clickable: true,
    },
  });
}

// Story tabs, contents, pagenation 
class StoryContents {
  constructor() {
    this.$tab = $('.stories-main-headline__item');
    this.$content = $('.stories-main-cnt');
    this.$pagenationNum = $('.stories-main-cnts__pagenation-num');
    this.$prevPagenation = $('.stories-main-cnts__pagenation-icons-icon--type_prev');
    this.$nextPagenation = $('.stories-main-cnts__pagenation-icons-icon--type_next');
    this.tabCurrentClassName = 'stories-main-headline__item--state_current';
    this.tabDisabledClassName = 'stories-main-headline__item--state_disabled';
    this.pagenationDisabledClassName = 'stories-main-cnts__pagenation-icons-icon--state_disabled';
    this.hiddenClassName = 'd-none';
    this.willPageNum = 1;
    this.firstPageNum = 1;
    this.lastPageNum = 5;
  }

  // Tabs
  changeTheTabCurrentState() {
    this.$tab.filter(`[data-pagenum=${this.willPageNum}]`).addClass(this.tabCurrentClassName);
  }

  changeTabsNormalState() {
    this.$tab.removeClass(this.tabCurrentClassName);
  }

  // Contents
  showTheContent() {
    $(`#stories-main-cnt_${this.willPageNum}`).removeClass(this.hiddenClassName).hide().fadeIn(500);
  }

  hideContents() {
    this.$content.hide();
  }

  // Pagenation
  updatePagenationNum() {
    this.$pagenationNum.text(`0${this.willPageNum}`);
  }

  updatePagenationArrows() {
    this.$prevPagenation.removeClass(this.pagenationDisabledClassName);
    this.$nextPagenation.removeClass(this.pagenationDisabledClassName);
    if (this.willPageNum <= this.firstPageNum) this.$prevPagenation.addClass(this.pagenationDisabledClassName);
    if (this.willPageNum >= this.lastPageNum) this.$nextPagenation.addClass(this.pagenationDisabledClassName);
  }

  // Utilities
  setWillPageNum(way, event) {
    if (way === 'tab') {
      const $self = $(event.currentTarget);
      this.willPageNum = Number($self.data('pagenum'));
    }
    if (way === 'inc') this.willPageNum += 1;
    if (way === 'dec') this.willPageNum -= 1;
  }

  getWillPageNum() {
    return this.willPageNum;
  }

  isEnableTab(event) {
    return !$(event.currentTarget).hasClass(this.tabDisabledClassName);
  }

  isEnablePagenationArrow(event) {
    return !$(event.currentTarget).hasClass(this.pagenationDisabledClassName);
  }

  isDefaultTabState(elm) {
    const $self = $(elm);
    if ($self.hasClass(this.tabCurrentClassName)) return false;
    if ($self.hasClass(this.tabDisabledClassName)) return false;
    return true;
  }

  manageStoryElements() {
    this.changeTabsNormalState();
    this.changeTheTabCurrentState();
    this.hideContents();
    this.showTheContent();
    this.updatePagenationNum();
    this.updatePagenationArrows();
  }

  setEvents() {
    this.$tab.on('click', (e) => {
      if (this.isEnableTab(e)) {
        this.setWillPageNum('tab', e);
        this.manageStoryElements();
      }
      return false;
    });

    this.$prevPagenation.on('click', (e) => {
      if (this.isEnablePagenationArrow(e)) {
        this.setWillPageNum('dec', e);
        this.manageStoryElements();
      }
      return false;
    });

    this.$nextPagenation.on('click', (e) => {
      if (this.isEnablePagenationArrow(e)) {
        this.setWillPageNum('inc', e);
        this.manageStoryElements();
      }
      return false;
    });
  }

  init() {
    this.setEvents();
  }
}

const storyContents = new StoryContents();
storyContents.init();
