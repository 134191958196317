import $ from 'jquery';
import Swiper from 'swiper';

// Main visual Slide Show
if (document.querySelector('.top-mv-imgs__slideshow') !== null) {
  // eslint-disable-next-line no-unused-vars
  const topMvSlideShow = new Swiper('.top-mv-imgs__slideshow .swiper-container', {
    speed: 3000,
    effect: 'fade',
    fade: {
      crossFade: true,
    },
    autoplay: {
      delay: 3000,
    },
    loop: true,
    pagination: {
      el: '.top-show-mv-imgs .swiper-pagination',
      clickable: true,
    },
  });
}

// Animation
// [note] 適切なラップ要素がないので、animation要素の一つをスクリプト実行のトリガーとする
if (document.querySelector('.top-balloon--type_1') !== null) {
  class TopAnimElm {
    constructor(elmSelector, isActiveClassName, isActiveOffsetNum) {
      this.$elm = $(elmSelector);
      this.isActiveClassName = isActiveClassName;
      this.isActiveOffsetNum = isActiveOffsetNum;
      this.AnimationStartTimeDelay = 300;
    }

    run() {
      const elmOffset = this.$elm.offset().top;
      const scrollPos = $(window).scrollTop();

      setTimeout(() => {
        if (scrollPos > elmOffset - this.isActiveOffsetNum) {
          this.$elm.addClass(this.isActiveClassName);
        } else {
          this.$elm.removeClass(this.isActiveClassName);
        }
      }, this.AnimationStartTimeDelay);
    }
  }

  const balloon1 = new TopAnimElm('.top-balloon--type_1', 'balloon-1-hide-fx', 100);
  const balloon2 = new TopAnimElm('.top-balloon--type_2', 'balloon-2-hide-fx', 200);
  const historyKanjiSymbol = new TopAnimElm('.top-theme-item__imgs-img--type_history', 'isActive', 500);
  const livingKanjiSymbol = new TopAnimElm('.top-theme-item__imgs-img--type_living', 'isActive', 500);
  const cultureKanjiSymbol = new TopAnimElm('.top-theme-item__imgs-img--type_culture', 'isActive', 500);

  $(window).on('scroll', () => {
      balloon1.run();
      balloon2.run();
      historyKanjiSymbol.run();
      livingKanjiSymbol.run();
      cultureKanjiSymbol.run();
  });
}


