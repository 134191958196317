import $ from 'jquery';
import scrollTo from '../utilities/ScrollTo'; // [note] ScrollTo serve instance.
import AllSpotsMapItem from '../parts/AllSpotsMapItem'

// Utilities
// -------------------------
scrollTo();

// Howto Modal
function initHowToModalSelector() {
  const $selectors = $('.howto-modal-cnts__pager');
  const $displays = $('.howto-modal-cnt');
  const displayLength = $displays.length;
  let pageIndex = 0;

  $selectors.on('click', event => {
    const $this = $(event.currentTarget);
    const type = $this.data('type');

    if (type === 'next' && pageIndex < displayLength - 1) {
      $displays.hide().removeClass('d-none');
      $selectors.removeClass('disabled');
      $displays.eq(pageIndex + 1).fadeIn(400);
      pageIndex += 1;
      if (pageIndex === displayLength - 1) $this.addClass('disabled');
    }

    if (type === 'prev' && pageIndex !== 0) {
      $displays.hide().removeClass('d-none');
      $selectors.removeClass('disabled');
      $displays.eq(pageIndex - 1).fadeIn(400);
      pageIndex -= 1;
      if (pageIndex === 0) $this.addClass('disabled');
    }
  });
}
initHowToModalSelector();


// Shared Parts
// -------------------------
// maki系, themes系ページの最下部のgoogle map
if (document.querySelector('#allSpotsMapItem') !== null) {
  // [note]
  // mapFitBoundsModeが有効であるため
  // 地図の中央点（centerPoint）と
  // 地図の倍率(zoom)を渡す必要がない
  const allSpotsMapItem = new AllSpotsMapItem({
    spotsInfo: document.querySelector('#allSpotsMapItem').dataset.spotsinfo,
    mapId: '#allSpotsMapItem',
    mapFitBoundsMode: true,
    // centerPoint: [35.613169, 140.113472],
    // zoom: 10,
  });
  allSpotsMapItem.init();
}
