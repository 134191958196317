export default class AllSpotsMapItem {
  constructor(allSpotsInfo) {
    const info = allSpotsInfo || {};
    // Base Information
    this.spotsInfo = JSON.parse(info.spotsInfo);
    this.mapCenterPoint = info.centerPoint || [35.6811673, 139.7670516];// <== tokyo station
    this.mapFitBoundsMode = info.mapFitBoundsMode || false;
    this.mapZoom = info.zoom || 15;
    this.mapId = info.mapId || '#map';
    this.mapTypeId = info.mapTypeId || 'roadmap';
    // GMap elements
    this.mapObj = '';
    this.mapMarkers = [];
    this.mapInfoWindows = [];
  }

  createMap() {
    const opts = {
      zoom: this.mapZoom,
      center: new window.google.maps.LatLng(this.mapCenterPoint[0], this.mapCenterPoint[1]),
      mapTypeId: this.mapTypeId,
    };
    this.mapObj = new window.google.maps.Map(document.querySelector(this.mapId), opts);
  }

  createSpots() {
    const markerBounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < this.spotsInfo.length; i += 1) {
      // markerの生成
      const markerPos = new window.google.maps.LatLng(
          this.spotsInfo[i].lat,
          this.spotsInfo[i].lng,
      );
      this.mapMarkers[i] = new window.google.maps.Marker({
        position: markerPos,
        map: this.mapObj,
      });
      // infoWindowの作成とmarkerのclickイベントの紐つけ
      this.mapInfoWindows[i] = new window.google.maps.InfoWindow({
        content: this.getInfoWindowTags(i),
      });
      this.mapMarkers[i].addListener('click', () => {
        this.mapInfoWindows.forEach(e => {
          e.close();
        });
        this.mapInfoWindows[i].open(this.mapObj, this.mapMarkers[i]);
      });

      if (this.mapFitBoundsMode) markerBounds.extend(markerPos);
    }
    if (this.mapFitBoundsMode) this.mapObj.fitBounds(markerBounds);
  }

  getInfoWindowTags(i) {
    let tags = '';
    tags += `<div class="all-spots-map-iw">`;
    tags += `<div class="all-spots-map-iw__name">${this.spotsInfo[i].name}</div>`;
    tags += `</div>`;
    return tags;
  }

  init() {
    this.createMap();
    this.createSpots();
  }
}
