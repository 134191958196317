import $ from 'jquery';

class Header {
  constructor() {
    this.$header = $('.site-header');
    this.$toggler = $('.site-header-toggler');
    this.$togglerDefaultIcon = $('.site-header-toggler__icon--type_bars');
    this.$togglerActiveIcon = $('.site-header-toggler__icon--type_close');
    this.$navWrapper = $('.site-header-nav');
    this.$subMenus = $('.site-header-nav__item-sub-menus');
    this.$dropTrigger = $('[data-header-elm-type="dropTrigger"]');
    this.$dropTarget = $('[data-header-elm-type="dropTarget"]');
    this.headerActiveClassName = 'is-sp-nav-mode-active';
    this.dropTriggerActiveClassName = 'is-active';
    this.hideClassName = 'd-none';
    this.showSpeed = 300;
    this.hideSpeed = 200;
    this.resizeTimer = false;
    this.isSpMode = false;
  }

  // nav wrapper
  showNavWrapper() {
     this.$navWrapper.hide().removeClass(this.hideClassName).fadeIn(this.showSpeed);
  }

  hideNavWrapper() {
     this.$navWrapper.fadeOut(this.hideSpeed);
  }

  // toggler
  showTogglerDefaultIcon() {
     this.$togglerDefaultIcon.hide().removeClass(this.hideClassName).fadeIn(this.showSpeed);
  }

  showTogglerActiveIcon() {
     this.$togglerActiveIcon.hide().removeClass(this.hideClassName).fadeIn(this.showSpeed);
  }

  hideTogglerDefaultIcon(cb) {
     this.$togglerDefaultIcon.fadeOut(this.hideSpeed, cb.bind(this));
  }

  hideTogglerActiveIcon(cb) {
     this.$togglerActiveIcon.fadeOut(this.hideSpeed, cb.bind(this));
  }

  // sub menus
  showSubMunes() {
    this.$subMenus.fadeIn(100);
  }

  hideSubMunes() {
    this.$subMenus.fadeOut(100);
  }

  // 子要素を持ったgnav item, lang nav item用
  showTheDropTarget(trigger) {
    const $trigger = $(trigger);
    const id = $trigger.data('id');
    this.$dropTarget.filter(`[data-id="${id}"]`).removeClass('d-lg-none').addClass('d-block').hide().fadeIn(100);
  }

  hideDropTargets() {
    this.$dropTarget.removeClass('d-block').fadeOut(100).addClass('d-lg-none');
  }

  // state manage methods
  activeSpNavMode() {
    this.$header.addClass(this.headerActiveClassName);
    this.showNavWrapper();
    this.hideTogglerDefaultIcon(this.showTogglerActiveIcon.bind(this));
    this.showSubMunes();
  }

  inactiveSpNavMode() {
    this.$header.removeClass(this.headerActiveClassName);
    this.hideNavWrapper();
    this.hideTogglerActiveIcon(this.showTogglerDefaultIcon.bind(this));
    this.hideSubMunes();
  }

  activeTheDropTarget(trigger) {
    $(trigger).addClass(this.dropTriggerActiveClassName);
    this.showTheDropTarget(trigger);
  }

  inactiveDropTargets() {
    this.$dropTrigger.removeClass(this.dropTriggerActiveClassName);
    this.hideDropTargets();
  }

  // events
  setEvents() {
    this.$toggler.on('click', () => {
      if (!this.$header.hasClass(this.headerActiveClassName)) {
        this.activeSpNavMode();
      } else {
        this.inactiveSpNavMode();
      }
    });

    // 子要素を持ったgnav item, lang nav item
    this.$dropTrigger.stop().on('click', (e) => {
      const $self = $(e.currentTarget);
      // spNavModeがアクティブでない場合のみドロップ要素は機能する
      if (!this.$header.hasClass(this.headerActiveClassName)) {
        if (!$self.hasClass(this.dropTriggerActiveClassName)) {
          this.inactiveDropTargets();
          this.activeTheDropTarget(e.currentTarget);
        } else {
          this.inactiveDropTargets();
        }
      }
      return false;
    });

    $(window).on('load resize', () => {
      if (this.resizeTimer !== false) clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
      if (this.$header.hasClass(this.headerActiveClassName)) this.inactiveSpNavMode();
      }, 500);
    });

    // [todo] these are hardcording.
    // 言語切り替えボタンを押した時、それがカレント言語だった場合は挙動を無効に
    $('.site-header-language__list-link--type_active').on('click', () => {
      return false;
    });
    $('.site-header-nav__language-list-link--type_active').on('click', () => {
      return false;
    });
  }

  init() {
    this.setEvents();
  }
}

const header = new Header();
header.init();
