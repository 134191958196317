// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
// import * as ActiveStorage from '@rails/activestorage';
// eslint-disable-next-line import/no-unresolved
// import 'channels';
import 'bootstrap';

// application
import '../application/header';
// parts
import '../parts/AllSpotsMapItem';
// pages
import '../pages/base';
import '../pages/top';
import '../pages/stories';
import '../pages/maki';
import '../pages/theme';

import '@fortawesome/fontawesome-free/js/all';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// eslint-disable-next-line import/no-unresolved
import 'controllers';
import '../japan_concierge/i18n/en';
import '../japan_concierge/i18n/fr';
import '../japan_concierge/i18n/id';
import '../japan_concierge/i18n/ja';
import '../japan_concierge/i18n/ko';
import '../japan_concierge/i18n/th';
import '../japan_concierge/i18n/vi';
import '../japan_concierge/i18n/zh-CN';
import '../japan_concierge/i18n/zh-TW';
import './server_rendering'

Rails.start();
// ActiveStorage.start();
