import $ from 'jquery';
import getUrlQueryAsObj from './GetUrlQueryAsObj';

class ScrollTo {
  constructor() {
    this.urlHash = window.location.hash;
    this.scrollSpeed = 500;

    if (this.urlHash) {
      const target = document.getElementById(this.urlHash.slice(1));
      const targetOffsetTop = target.getBoundingClientRect().top; // scroll位置からelmまで
      const documentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop; // ページ最上部からscroll位置まで
      const targetTop = targetOffsetTop + documentScrollTop;
      const urlQueries = getUrlQueryAsObj();
      let offset = 0;
      if (urlQueries.scrollOffset) offset = Number(urlQueries.scrollOffset);
      const position = targetTop - offset;
      setTimeout(() => {
        $('body, html')
          .stop()
          .scrollTop(0)
          .animate({ scrollTop: position }, this.scrollSpeed, 'swing');
      }, 200);
    }
    $('a[href^="#"]').click(event => {
      const $this = $(event.currentTarget);
      const href = $this.attr('href');
      const target = $(href === '#' || href === '' ? 'html' : href);
      const offset = $this.data('offset') ? $this.data('offset') : 0;
      const position = target.offset().top - offset;
      event.preventDefault();
      $('html, body').animate(
        { scrollTop: position },
        this.scrollSpeed,
        'swing',
      );
    });
  }
}

const instance = new ScrollTo();

function getInctance() {
  return instance;
}

export default getInctance; // Singleton
