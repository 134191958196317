class GetUrlQueryAsObj {
  constructor() {
    this.queryObj = {};
    this.urlQueries = window.location.search;
    // [note] 1は?除去のため。like => ["offset=145", "hoge=10"]
    this.queriesArr = this.urlQueries.slice(1).split('&');
    for (let i = 0; i < this.queriesArr.length; i += 1) {
      const array = this.queriesArr[i].split('=');
      const key = array[0];
      const val = array[1];
      this.queryObj[key] = val;
    }
    return this.queryObj;
  }
}

const instance = new GetUrlQueryAsObj();

function getInstance() {
  return instance;
}

export default getInstance; // Singleton
