import Swiper from 'swiper';

// All Spots Carousel
if (document.querySelector('.maki-swiper') !== null) {
  // eslint-disable-next-line no-unused-vars
  const testSwiper = new Swiper('.maki-swiper .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.maki-swiper .cmn-carousel__pager-inner--type_next',
      prevEl: '.maki-swiper .cmn-carousel__pager-inner--type_prev',
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      420: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
  });
}
